<template>
  <div class="profile-section">
    <div class="profile-header">
      <h3>Manage Profile</h3>
      <div class="action">
        <SubmitButton @onButtonClick="cancelEdit" :disabled="true" v-if="!isView">Cancel</SubmitButton>
        <SubmitButton @onButtonClick="toggleEdit">{{isView ? 'Edit' : 'Save'}}</SubmitButton>
      </div>
    </div>
    <div class="profile-wrapper" v-if="this.userData != null">
      <div class="form">
        <div class="form-group">
          <div class="label">Display Name</div>
          <div class="info">
            <template v-if="isView">
              {{userData.display_name || ""}}
            </template>
            <template v-else>
              <input
                class="info-input"
                v-model="userData.display_name"
                type="text"
                placeholder="Enter Display Name"
              />
            </template>
          </div>
        </div>
        <div class="form-group">
          <div class="label">
            Name (As per NRIC/Passport/Other ID)
          </div>
          <div class="info">
            <template v-if="isView">
              {{userData.name }}
            </template>
            <template v-else>
              <input
                class="info-input"
                v-model="userData.name"
                type="text"
                placeholder="Enter Name"
              />
            </template>
          </div>
        </div>
        <div class="form-group">
          <div class="label">Mobile Number</div>
          <div class="info">
            <template v-if="isView">
              {{userData.phone}}
            </template>

            <template v-else>
              <input
                class="info-input"
                v-model="userData.phone"
                type="text"
                placeholder="Enter No."
              />
            </template>
            
          </div>
        </div>
        <div class="form-group">
          <div class="label">Email</div>
          <div class="info">
            <template v-if="isView">
              {{userData.email}}
            </template>
            <template v-else>
              <input
                disabled
                class="info-input"
                v-model="userData.email"
                type="text"
                placeholder="Enter Email"
              />
            </template>
          </div>
        </div>
        <div class="form-group">
          <div class="label">Nationality</div>
          <div class="info" v-if="userData.country">
            <template v-if="isView">
              {{userData.country.name}}
            </template>

            <template v-else>
              <v-select
                class="info-select v-select-box"
                v-model="userData.country"
                :options="countryList"
                label="name"
                :clearable="false"
              ></v-select>
            </template>
          </div>
        </div>
          <div class="form-group">
          <div class="label">State</div>
          <div class="info" v-if="userData.state">
            <template v-if="isView">
              {{userData.state.name}}
            </template>

            <template v-else>
              <v-select
                class="info-select v-select-box"
                v-model="userData.state"
                :options="stateList"
                label="name"
                :clearable="false"
              ></v-select>
            </template>
          </div>
        </div>
        <div class="form-group">
          <div class="label">Gender</div>
          <div class="info">
            <template v-if="isView">
              {{userData.gender | gender}}
            </template>
            <template v-else>
              <v-select
                class="info-select v-select-box"
                :value="getGender"
                :options="genderList"
                @input="setGender"
                :clearable="false"
              />
            </template>
            
          </div>
        </div>
        <div class="form-group">
          <div class="label">Password</div>
          <div class="info">
           <template v-if="isView">
              ******
            </template>
            <template v-else>
              <SubmitButton @onButtonClick="resetPassword">Reset Password</SubmitButton>
            </template>
          </div>
        </div>
        <div class="form-group">
          <div class="label">Role</div>
          <div class="info">
            <template v-if="isView">
              {{userData.role.name | capitalize}}
            </template>
            <template v-else>
              <v-select
                class="info-select v-select-box"
                :value="userData.role.name"
                :options="roleList"
                v-model="userData.role.name"
                :clearable="false"
              />
            </template>
          </div>
        </div>

        <div class="form-group" v-if="isView">
          <div class="label">Successful Referrals</div>
          <div class="info">
            <SubmitButton
              @onButtonClick="$router.push({ name: 'SuccessRefAdmin' })"
              class="view-btn"
              >View Details</SubmitButton
            >
          </div>
        </div>
      </div>
      <div class="error">{{error}}</div>
    </div>
  </div>
</template>

<script>
import SubmitButton from '@/components/SubmitButton';
import {eventBus} from '@/main.js'
import UploadAttachment from "@/components/UploadAttachment";

//API
import { getUser, updateUser } from "@/services/adminAPI/users.js";
import { forgotPassword } from "@/services/api/users.js";
import { getCountry, getState } from "@/services/api/countries.js";


export default {
  components:{
    SubmitButton,
    UploadAttachment
  },
  props: ['user'],
  data(){
    return{
      isView: true,
      userData: null,
      tempUserData: null,
      countryList: [],
      stateList: [],
      roleList: ['user','trainer','admin'],
      genderList: ["Female", "Male"],
      dataToSend: null,
      error: ''
    }
  },
  methods:{
    toggleEdit(){
      console.log('toggle');
      if(this.isView){
        this.isView = false
      }else{
        //Call update method
        this.updateUserProfile();
      }
    },
    setGender(item) {
      this.userData.gender = item === "Female" ? "F" : "M";
    },
    updateUserProfile(){
      this.error = '';
      //Destructure data for returning to update API
      this.dataToSend = (({display_name, name, phone, gender,country,state, role}) => (
        {
          display_name, 
          name, 
          phone, 
          gender,
          country_id: country.id, 
          state_id: state.id, 
          role_name : role.name
        }
      ))(this.userData)

      if(!Object.values(this.dataToSend).some(value => (value !== null && value !== ''))) return;

      eventBus.$emit('loader',true);
      updateUser(this.userData.id,this.dataToSend).then((updateUserAPI) => {
        this.userData = updateUserAPI.data.response.user;
        this.tempUserData = this.userData;

        eventBus.$emit('loader',false);
        this.isView = true;
      }).catch((error) => {
        eventBus.$emit('loader',false);
        this.error = error.response.data.userMessage;
      })
    },
    cancelEdit(){
      this.userData = Object.assign({},this.tempUserData);
      this.isView = true;
    },
    resetPassword(){
      eventBus.$emit('loader',true);
      forgotPassword(this.userData.email).then((forgotPasswordAPI) => {
        const vm = this;
        this.$swal({
          icon: 'success',
          title: 'Reset Password',
          text: `An email has been sent to ${vm.userData.email} for furter actions`,
          showConfirmButton: true,
          confirmButtonText: 'Ok',
        })
      }).catch((error) => {
        this.error = error.response.data.userMessage;
        eventBus.$emit('loader',false);
      })
    }
  },
  filters:{
    gender(gender){
      return gender === 'M' ? 'Male' : 'Female'
    },
    capitalize(string){
      return string.charAt(0).toUpperCase() + string.slice(1);
    }
  },
  computed:{
    getGender() {
      return this.userData.gender === "F" ? "Female" : "Male";
    },
  },
  async mounted(){
    eventBus.$emit('loader',true);

    if(!this.user){
      const getUserAPI = await getUser({});
      this.userData = getUserAPI.data.response.users.data.filter((data) => data.id == this.$route.params.userId)[0]
      eventBus.$emit('loader',false);
    }else{
      this.userData = this.user
      eventBus.$emit('loader',false);
    }

    this.tempUserData = Object.assign({},this.userData);

    try {
      const getCountryAPI = await getCountry();
      const getStateAPI = await getState(this.userData.country_id);
      this.countryList = getCountryAPI.data.response.countries;
      this.stateList = getStateAPI.data.response.states;
       
    } catch (error) {
      console.log(error);
    }
  }
}
</script>

<style lang="scss" scoped>
.profile-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action{
  display: flex;
  flex-wrap: wrap;

  & > *{
    margin-left: 10px;
  }
}


.profile-wrapper {
  position: relative;
  display: grid;
  grid-template-columns: 4fr 1fr;
  grid-template-areas: "form error";
  gap: 0.5rem;
  @include respond(tab-port) {
    grid-template-columns: 1fr;
    grid-template-areas:
      "error"
      "form";
    text-align: start;
    font-size: $twelve;
  }
  .form {
    grid-area: form;
    position: relative;
    &-group {
      display: flex;
      flex-direction: row;
      padding: 0.5rem;
      align-items: center;

      .label {
        width: 40%;
        // width: 20rem;

        &::after {
          content: "";
          height: 100%;
          position: absolute;
          top: 0%;
          left: 40%;
          border-right: 1px solid $gray;

          @include respond(tab-port) {
            content: "";
            height: 100%;
            width: 100%;
            top: 0;
            left: 0;
            border-top: $gray solid 1px;
            border-right: 0;
          }
        }
      }
      .info {
        width: 60%;
        margin: auto;
        margin-left: $ten;

        &-input {
          width: 100%;
          font-size: initial;
          padding: $ten;
          margin: 0;
        }
      }
      .view-btn {
        padding: 0.75rem 1rem;
        font-size: 12px;
        word-wrap: normal;
      }
    }
  }

  .error {
    grid-area: error;
    width: 100%;
    margin: $fourteen auto;
    color: red;
  }
}
</style>