<template>
  <div>
    <vue-good-table
      v-if="leaderboards && leaderboards.length"
      :columns="columns"
      :rows="leaderboards"
      :pagination-options="{
        enabled: true,
        pageLabel: 'page', // for 'pages' mode
      }"
      styleClass="vgt-table striped"
    >
      <template slot="table-row" slot-scope="props">
        <span
          v-if="props.column.field === 'report'"
          @click="downloadReport(props.row.id)"
        >
          <span
            ><div class="btn"><font-awesome-icon icon="edit" /></div
          ></span>
        </span>
        <span v-else-if="props.column.field === 'roi'">
          <span style="color:#009F00" v-if="parseFloat(props.row.roi) > 0.0">
            {{ props.row.roi }}</span
          >
          <span
            style="color:#c41230"
            v-else-if="parseFloat(props.row.roi) < 0.0"
          >
            {{ props.row.roi }}</span
          >
          <span v-else> {{ props.row.roi }}</span>
        </span>
        <span v-else-if="props.column.field === 'ranking'">
          <span> {{ props.row.ranking }}</span>
        </span>
        <span v-else>
          <span> {{ props.formattedRow[props.column.field] }}</span>
        </span>
      </template>
    </vue-good-table>
    <vue-html2pdf
      :show-layout="show"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :pdf-quality="2"
      pdf-format="a4"
      pdf-orientation="portrait"
      :manual-pagination="true"
      :paginate-elements-by-height="1100"
      :html-to-pdf-options="htmlToPdfOptions"
      @beforeDownload="beforeDownload($event)"
      ref="html2Pdf"
    >
      <PDFContent slot="pdf-content" :report="reportData" :key="counter" />
    </vue-html2pdf>
  </div>
</template>

<script>
import moment from "moment";
import VueHtml2pdf from "vue-html2pdf";
import PDFContent from "../../../Home/Profile/components/PDFContent.vue";
import { eventBus } from "@/main.js";

import { gameReport } from "@/services/api/game.js";

import { leaderboardPersonal } from '@/services/adminAPI/users.js'

export default {
  components: {
    VueHtml2pdf,
    PDFContent,
  },
  data() {
    return {
      leaderboards: [],
      reportData: null,
      headers: [
        "Date",
        "Game Type",
        "Game Name",
        "ROI (%)",
        "Ranking",
        "Report",
      ],
      columns: [
        {
          label: "Id",
          field: "id",
          hidden: true,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "Date",
          field: "created_at",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "Game Type",
          field: "type",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "Game Name",
          field: "name",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "ROI (%)",
          field: "roi",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
        {
          label: "Ranking",
          field: "ranking",
          hidden: false,
          sortable: true,
          type: "number",
          thClass: "table-row align-left",
          tdClass: "align-left",
        },
        {
          label: "Report",
          field: "report",
          hidden: false,
          sortable: true,
          thClass: "table-row",
        },
      ],
      htmlToPdfOptions: {
        margin: 0,
        filename: "report",
        html2canvas: {
          scale: 2,
          dpi: 300,
          letterRendering: true,
          useCORS: true,
        },
        image: {
          type: "jpeg",
          quality: 2,
        },
        enableLinks: true,
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait",
        },
      },
      show: false,
      counter: 0,
    };
  },
  async created() {
    try {

      const userId = this.$route.params.userId;

      const leaderboardPersonalAPI = await leaderboardPersonal({userId});

      this.leaderboards = leaderboardPersonalAPI.data.response.leaderboards;


    } catch (error) {
      console.log(error);
    }
  },
  methods: {
    formattedDate(item) {
      return moment(item).format("DD-MMM-YYYY");
    },
    toggleOrder(header) {
      this.$emit("toggleOrder", header);
    },
    async downloadReport(id) {
      // this.$swal({
      //   title: 'Coming Soon...',
      // })
      // return;

      eventBus.$emit("loader", true);
      try {
        const gameReportAPI = await gameReport(id);

        this.downloadFileName = `${gameReportAPI.data.response.gameSession.game.name}-${gameReportAPI.data.response.gameSession.start_time}`;

        if (gameReportAPI.status == 200) {
          this.reportData = gameReportAPI.data.response;
          this.counter++;

          this.$refs.html2Pdf.generatePdf();
          eventBus.$emit("loader", false);
        }

        // this.show = true;
      } catch (error) {
        eventBus.$emit("loader", false);
        // this.$swal({
        //   icon: 'error',
        //   title: 'Oops!',
        //   text: error.response.data.userMessage
        // })
        console.log(error);
      }
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      await html2pdf()
        .set(options)
        .from(pdfContent)
        .toPdf()
        .get("pdf")
        .then((pdf) => {
          const totalPages = pdf.internal.getNumberOfPages();
          for (let i = 1; i <= totalPages; i++) {
            pdf.setPage(i);

            // Title
            pdf.setFontSize(13);
            pdf.setTextColor("#c41230");
            pdf.text(
              "Kenanga Futures Sdn Bhd",
              0.1,
              pdf.internal.pageSize.getHeight() - 0.75
            );

            // Company Registration No
            pdf.setFontSize(10);
            pdf.setTextColor("#00070d");
            pdf.text(
              "199501024398 (353603-X)",
              2.3,
              pdf.internal.pageSize.getHeight() - 0.75
            );

            // Address
            pdf.setFontSize(11);
            pdf.setTextColor("#414241");
            pdf.text(
              "Level 6, Kenanga Tower, 237 Jalan Tun Razak, 50400 Kuala Lumpur, Malaysia",
              0.1,
              pdf.internal.pageSize.getHeight() - 0.55
            );

            // Contact
            pdf.text(
              "Tel: (603) 2172 3860 Fax: (603) 2172 2929 Email: BCRM@kenanga.com.my",
              0.1,
              pdf.internal.pageSize.getHeight() - 0.35
            );

            // Website
            pdf.text(
              "Website: https://kenangafutures.com.my",
              0.1,
              pdf.internal.pageSize.getHeight() - 0.15
            );
          }
        })
        .save();
    },
  },
  computed: {
    personalLeaderBoardList() {
      return this.$store.getters.getPersonalLeaderboardList;
    },
  },
  watch: {
    personalLeaderBoardList(data) {
      this.leaderboards = data;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ table.vgt-table td {
  vertical-align: middle;
}

/deep/ .align-left {
  text-align: left !important;
}
</style>
