<template>
  <div>
    <Container class="user-wrapper">
      <ProfileComponent :user="user" />
    </Container>

    <Container style="margin-top: 1rem">
      <div class="first-wrapper">
        <h3>Gameplay (History / Performance)</h3>
        <!-- <div class="search-container">
          <font-awesome-icon class="icon" icon="search" />
          <input
            class="input-field"
            type="text"
            placeholder="Search: e.g date, game name"
            @keyup="search"
            v-model="searchValue"
          />
        </div> -->
      </div>
      <GameplayLeaderboard @toggleOrder="setOrder"/>
    </Container>
  </div>
</template>

<script>
import ProfileComponent from "@/components/ProfileComponent.vue";
import GameplayLeaderboard from "../components/GameplayLeaderboard.vue"
import { eventBus } from "@/main.js";
export default {
  props: ["user"],
  components: {
    ProfileComponent,
    GameplayLeaderboard
  },
  data() {
    return {};
  },
  async created() {},
  methods:{
    setOrder(){
      
    }
  }
};
</script>

<style lang="scss">
.first-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1rem;
  @include respond(tab-port) {
    flex-direction: column;
    gap: 1rem;
  }
  .search-container {
    display: flex;
    width: 50%;
    border: 1px solid black;
    @include respond(tab-port) {
      width: 100%;
    }
    .icon {
      margin: auto 0.5rem;
      color: #efefef;
    }
    .input-field {
      padding: 0.625rem;
      width: 100%;
      border: none;
    }
  }
}
</style>
